<template>
  <div class="form">
    <div class="row">
      <Input
        type="text"
        title="Nom"
        v-model="employee.lastname"
      />
      <Input
        type="text"
        title="Prénom"
        v-model="employee.firstname"
      />
    </div>
    
    <Input
      type="text"
      title="Email"
      v-model="employee.email"
    />

    <div class="row">
      <MultipleSelect
        title="Personas"
        :options="['Admin', 'Compta', 'Facturation', 'Téléopérateur', 'Expert Lifebloom', 'Resp Affaire', 'Technicien', 'Chef de Secteur', 'Chef d\'assemblage']"
        v-model="employee.personas"
      />

      <MultipleSelect
        title="Agences / Usines"
        :options="['Agence HdF', 'Agence Paris', 'Agence IdF']"
        v-model="employee.agencies"
      />
    </div>

    <div class="buttons-container">
      <Button
        title="Sauvegarder les modifications"
        type="primary"
      />
      <Button
        title="Supprimer"
        type="warning"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/Forms/Fields/Input'
import MultipleSelect from '@/components/Forms/Fields/MultipleSelect'
import Button from '@/components/Button'

export default {
  name: 'EmployeeForm',
  components: {
    Input, MultipleSelect, Button
  },
  props: {
    employee: {
      default: function () {
        return {
          firstname: '',
          lastname: '',
          email: '',
          accountCreation: '',
          personas: [],
          agencies: []
        }
      }
    },
    mode: {
      type: String,
      default: 'edit'
    }
  }
}
</script>

<style lang="scss" scoped>
.form{
  .row{
    div {
      flex: 1;
    }
  }

  .buttons-container{
    display: flex;
    justify-content: center;
    margin: 20px 0;

    div {
      flex: 0;
    }
  }
}
</style>
