<template>
  <AdminView>
    <router-link class="back-link" :to="{ name: 'EmployeesList' }">&lt; Retour</router-link>

    <div v-if="mode === 'edit'">
      <h1 class="title-1">Modifier l'employé</h1>
      <EmployeeForm :employee="employee" mode="edit" />
    </div>

    <div v-if="mode === 'add'">
      <h1 class="title-1">Ajouter un employé</h1>
      <EmployeeForm mode="add" />
    </div>
  </AdminView>
</template>

<script>
import AdminView from '@/components/AdminView'
import EmployeeForm from '@/components/Forms/EmployeeForm'

export default {
  name: 'Employee',
  components: {
    AdminView, EmployeeForm
  },
  props: {
    mode: {
      type: String,
      default: 'edit'
    }
  },
  data () {
    return {
      employee: {
        id: 1,
        firstname: 'John',
        lastname: 'Doh',
        email: 'test@test.com',
        accountCreation: '27/04/2021',
        personas: ['Compta', 'Facturation'],
        agencies: ['Agence Paris', 'Agence IdF']
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
